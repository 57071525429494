.wrapper {
  position: sticky;
  top: 0;
  z-index: 10;
}

.section {
  width: 99%;
}

.right-arrow {
  width: 0;
  height: 0;
  border-left: 35px solid white;
  border-top: 35px solid transparent;
  border-bottom: 35px solid transparent;
}

.social-icon {
  image-rendering: optimizeSpeed; /* STOP SMOOTHING, GIVE ME SPEED  */
  image-rendering: -moz-crisp-edges; /* Firefox                        */
  image-rendering: -o-crisp-edges; /* Opera                          */
  image-rendering: -webkit-optimize-contrast; /* Chrome (and eventually Safari) */
  image-rendering: pixelated; /* Universal support since 2021   */
  image-rendering: optimize-contrast; /* CSS3 Proposed                  */
  -ms-interpolation-mode: nearest-neighbor; /* IE8+                           */
}
